<template>
  <div class="contact">
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    />
    <!-- Page Title Area Start -->
    <section class="page-title-area bg-image ptb--80">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h1 class="page-title">Contact Us</h1>
          </div>
        </div>
      </div>
    </section>
    <!-- Page Title Area End -->
    <!-- Breadcrumb Area Start -->
    <div class="breadcrumb-area mb--60">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <ul class="breadcrumb">
              <li><router-link to="/">Home</router-link></li>
              <li class="current"><span>Contact Us</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Breadcrumb Area End -->
    <!-- Main Content Wrapper Start -->
    <div class="main-content-wrapper">
      <!-- News Area Start -->
      <div class="inner-content-area mt--55 mb--60">
        <div class="container">
          <div class="row mb--60">
            <div class="col-lg-5 mb-md--45">
              <h3 class="heading__tertiary mb--20">Get In Touch</h3>
              <div class="contact-info mb--40">
                <p v-if="infoData.info_address">
                  <i class="fa fa-map-marker"></i>{{ infoData.info_address }}
                </p>
                <p v-if="infoData.info_com_phone01">
                  <i class="fa fa-phone"></i> +91-{{
                    infoData.info_com_phone01
                  }}
                </p>
                <p v-if="infoData.info_com_phone02">
                  <i class="fa fa-phone"></i> +91-{{
                    infoData.info_com_phone02
                  }}
                </p>
                <p v-if="infoData.info_email">
                  <i class="fa fa-envelope"></i> {{ infoData.info_email }}
                </p>
                <p><i class="fa fa-clock-o"></i> Mon – Fri : 9:00 – 18:00</p>
              </div>
              <ul class="social social-round mb--50">
                <li class="social__item" v-if="infoData.info_facebook">
                  <a :href="infoData.info_facebook" class="social__link">
                    <i class="fa fa-facebook"></i>
                  </a>
                </li>
                <li class="social__item" v-if="infoData.info_youtube">
                  <a :href="infoData.info_youtube" class="social__link">
                    <i class="fa fa-youtube-play"></i>
                  </a>
                </li>
                <li class="social__item" v-if="infoData.info_telegram">
                  <a :href="infoData.info_telegram" class="social__link">
                    <i class="fa fa-telegram"></i>
                  </a>
                </li>
              </ul>
              <!-- <div id="google-map"></div> -->
            </div>
            <div class="col-lg-7">
              <h3 class="heading__tertiary mb--20">Contact Us</h3>
              <p class="mb--15">
                Would you like to speak to one of our financial advisers over
                the phone? Just submit your details and we’ll be in touch
                shortly. You can also e-mail us for any further concern.
              </p>
              <!-- Contact form Start Here -->
              <form class="form" @submit.prevent="submitForm">
                <div class="form__group mb--20">
                  <input
                    type="text"
                    v-model="form.name"
                    name="contact_name"
                    class="form__input"
                    placeholder="Your name (required)"
                  />
                </div>
                <div class="form__group mb--20">
                  <input
                    type="phone"
                    v-model="form.phone"
                    name="contact_phone"
                    class="form__input"
                    placeholder="Your Phone (required)"
                  />
                </div>
                <div class="form__group mb--20">
                  <input
                    type="text"
                    v-model="form.city"
                    name="contact_city"
                    class="form__input"
                    placeholder="Your City (required)"
                  />
                </div>
                <div class="form__group mb--20">
                  <input
                    type="email"
                    v-model="form.email"
                    name="contact_email"
                    class="form__input"
                    placeholder="Your Email Address"
                  />
                </div>
                <div class="form__group mb--20">
                  <textarea
                    type="text"
                    class="form__input form__input--textarea"
                    v-model="form.description"
                    name="contact_message"
                    placeholder="Your Message"
                  ></textarea>
                </div>
                <div class="form__group">
                  <button type="submut" class="btn">Submit</button>
                </div>
                <!-- <div class="form__output"></div> -->
              </form>
              <!-- Contact form end Here -->
            </div>
          </div>
        </div>
      </div>
      <!-- News Area End -->
    </div>
    <!-- Main Content Wrapper End -->
  </div>
</template>
<script>
import { API_URL } from "../router/api";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
export default {
  name: "Contact Us",
  setup() {
    const Swal = require("sweetalert2");
    const axios = require("axios");
    const store = useStore();
    const isLoading = ref(false);
    const fullPage = ref(false);

    const form = ref({});

    onMounted(() => {
      window.scrollTo(0, 0);
      store.dispatch("getInfoShow");
      isLoading.value = true;
      setTimeout(() => {
        isLoading.value = false;
      }, 2000);
    });
    const infoData = computed(() => {
      return store.state.infoData;
    });
    const submitForm = () => {
      console.log(form.value.name);
      let api = {
        callBack() {
          axios
            .post(`${API_URL}add_contact_us`, form.value)
            .then((response) => {
              let obj = response.data;
              if (obj.status === true) {
                Swal.fire("Success!", "You clicked the button!", "success");
                form.value = {};
              } else {
                Swal.fire("Error!", "You clicked the button!", "error");
              }
            });
        },
      };
      form.value.name === undefined
        ? Swal.fire("Name Empty!", "You clicked the button!", "warning")
        : form.value.phone === undefined
        ? Swal.fire("Phone Empty!", "You clicked the button!", "warning")
        : form.value.city === undefined
        ? Swal.fire("City Empty!", "You clicked the button!", "warning")
        : api.callBack();
    };

    return {
      infoData,
      isLoading,
      fullPage,
      form,
      submitForm,
    };
  },
};
</script>
<style></style>
